import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Auth0Lock from 'auth0-lock';
import auth0 from 'auth0-js';
import axios from 'axios';
import Modal from 'antd/lib/modal/Modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getCookie, setCookie, notify,
  removeCookies,
} from '../../../utils';
import Loader from '../../components/Loader';
import { setSession, removeSession } from '../Status/logic';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ modal }) => {
  const webAuth = new auth0.WebAuth({
    domain: process.env.auth0.domain,
    clientID: process.env.auth0.clientId,
    audience: process.env.auth0.audience,
    responseType: 'token id_token',
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')

  const lock = new Auth0Lock(process.env.auth0.clientId, process.env.auth0.domain, {
    theme: {
      logo: '',
      primaryColor: '#ffc100',
    },
    languageDictionary: {
      title: '',
      signUpTitle: '',
    },
    container: 'container',
    auth: {
      responseType: 'token id_token',
      audience: process.env.auth0.audience,
    },

    rememberLastLogin: false,
    allowShowPassword: true,
  });

  const loginSuccess = (response) => {
    if (response.data.relogin_flag) {
      setShowModal(true)
      setLoading(false)
      setMessage(response.data.message)
    } else {
      setShowModal(false)
      setMessage('')
      const userName = `${response.data.data.profile.id}`
      const bearer = `Bearer ${response.data.data.accessToken}`
      setCookie(JSON.stringify(response.data.data.permissions), 'permissions')
      setCookie(response.data.data.refreshToken, 'refreshToken')
      setCookie(bearer, 'accessToken')
      setCookie(response.data.data.profile.first_name, 'name')
      setCookie(response.data.data.profile.last_name, 'lastName')
      setCookie(userName, 'userName')
      setCookie(response.data.data.profile.organization || 'individual', 'org')
      if (response?.data?.data?.is_trial_login) {
        setCookie(true, 'trialUser')
        localStorage.setItem('expiry-trial-period', response.data.data.expiry_time)
      }

      dispatch(setSession(`Bearer ${response.data.data.accessToken}`));

      setLoading(false);
    }
  };

  const getToken = async (token) => {
    try {
      const response = await axios.get(`${process.env.apiUrl}v0/auth/callback`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        loginSuccess(response);
      }

      return response.data;
    } catch (err) {
      setLoading(false)
      if (err.response) {
        if (err.response.status === 404 && err.response.data.relogin_flag) {
          setShowModal(true);
          setMessage(err.response.data.message || 'An error occurred');
        }
      } else {
        webAuth.logout({
          returnTo: process.env.appUrl,
          clientID: process.env.auth0.clientId,
        });
        notify('Oops! There has been an issue. Re-try in some time.', 'error');
        dispatch(removeSession())
      }
      return null;
    }
  };

  useEffect(() => {
    if (!getCookie('loggedIn')) {
      lock.show();
    }
    if (location.hash.includes('access_token')) {
      setLoading(true);
      lock.on('authenticated', (authResult) => {
        setCookie(true, 'loggedIn');
        lock.getUserInfo(authResult.accessToken, (error) => {
          if (error) {
            setLoading(false);
            setCookie(false, 'loggedIn');
            notify('Oops! There has been an issue. Re-try in some time.', 'error');
            // Handle error
            return;
          }
          if (!authResult) {
            return;
          }
          localStorage.setItem('accessToken', authResult.accessToken);
          getToken(authResult.accessToken);
        });
      });
    }
  }, []);

  const onOkClick = () => {
    history.replace(process.env.mainRoute);
    removeCookies();
    webAuth.logout({
      returnTo: process.env.appUrl,
      clientID: process.env.auth0.clientId,
    })
    setShowModal(false)
    setMessage('')
  }

  return (
    <Loader height={modal ? 425 : 'calc(100vh - 100px)'} loading={loading} error={false}>
      <div className="login-container login-page landscape-login">
        <div className="landsacape-login-page">
          <div className="landsacape-login-page-info">
            <div className="landsacape-login-page-info-logo-both">
              <div className="logo-parexel" />
              <div className="logo-divider" />
              <div className="logo-inno" />
            </div>
            <div className="landsacape-login-page-info-logo">
              <div className="landsacape-login-page-info-logo-img" />
            </div>
            <div className="landsacape-login-page-info-text">
              LandSCAPE is a Site and Investigator Intelligence platform that facilitates efficient
              data-driven study planning. It helps in analyzing the feasibility of a given study and
              shortlisting sites/investigators based on performance, reliability, quality and
              capabilities. Moreover, LandSCAPE encourages collaboration and knowledge sharing for
              clinical feasibility. It also allows organizations to keep the site and investigator
              knowledge up-to-date.
            </div>
            <div className="landsacape-login-page-account">
              {/* <div className="landsacape-login-page-account-title">Login to Your Account</div> */}
              {/* <div
                className="landsacape-login-page-account-data"
                style={{ height: 320 }}
                id="ssoLogin"
              /> */}

              <div
                id="container"
                className="landsacape-login-page-account-data"
                // style={{ height: 320 }}
              />

              <div className="landsacape-login-page-account-usermail">
                <div className="landsacape-login-page-account-text">Landscape licenses are limited to the Launch Excellence Strategy Team, Site Alliances, and site list validation users (including Country Heads).</div>
                <div className="landsacape-login-page-account-text">Wider organization access is not planned, as Veeva Ready records will be visible in Vault Clinical’s Global Directory. </div>
                <div>For more information, visit the <a href="https://parexel.sharepoint.com/sites/Launch_Excellence/SitePages/Landscape.aspx" target="_blank" rel="noopener noreferrer">Landscape SharePoint Page. </a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
      showModal && (
        <Modal
          title=""
          open={showModal}
          closable={false}
          className="modal-wrapper relogin-modal"
          maskClosable={false}
          destroyOnClose
          onOk={() => onOkClick()}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div className="modal-content">
            <div className="modal-content-text">{message} </div>
          </div>
        </Modal>
      )
    }
    </Loader>
  );
};

Login.propTypes = {
  // app: PropTypes.string,
  modal: PropTypes.bool,
};

Login.defaultProps = {
  modal: process.env.isAuthModal || false,
};

export default Login;
